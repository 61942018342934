<template>
  <li
    class="hm-tab"
    :class="{
      'hm-tab--active': active,
      'hm-tab--border-active': type === 'border-active',
      'hm-tab--text-active': type === 'text-active',
    }"
    @click="onClick"
  >
    <slot></slot>
  </li>
</template>

<script lang="ts">
export default {
  name: 'HMTab',
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'border-active',
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    onClick(): void {
      this.$parent.selectTab(this.name);
    },
  },
  watch: {
    '$parent.modelValue': {
      handler(value: string) {
        this.active = this.$parent.modelValue === this.name;
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components-variables.scss';
.hm-tab {
  position: relative;
  top: 1px;
  display: flex;
  align-items: center;
  list-style: none;
  margin-right: 1.625rem;
  padding-bottom: 0.625rem;
  cursor: pointer;
  font-weight: 500;
  border-bottom: 3px solid transparent;
  border-top: 2px solid transparent;

  &--border-active.hm-tab--active {
    color: $charcoal;
    border-bottom: 3px solid var(--brand-color, $coral);
  }

  &--text-active.hm-tab--active {
    color: var(--brand-color, $coral);
  }

  @include media-breakpoint-down(md) {
    margin-right: 0.625rem;
    font-size: 0.825rem;
  }
}
</style>
