<template>
  <ul class="hm-tabs">
    <slot></slot>
  </ul>
</template>

<script lang="ts">
export default {
  name: 'HMTabs',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  methods: {
    selectTab(value: string): void {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.hm-tabs {
  overflow: hidden;
  overflow-x: auto;

  display: flex;
  margin: 0 0 1px 0;
  padding: 0;
  border-bottom: 1px solid $light-gray;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
